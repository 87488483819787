/* assistant-regular - latin */
@font-face {
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/assistant-v5-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Assistant'), local('Assistant-Regular'),
    url('../fonts/assistant-v5-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/assistant-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/assistant-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/assistant-v5-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/assistant-v5-latin-regular.svg#Assistant') format('svg'); /* Legacy iOS */
}

html {
    width: 100%;
    height: 100%;
    overflow: auto !important;
    font-size: 16px;
}

body {
    width: 100%;
    height: 100%;
    color: #000000A5;
    overflow: auto !important;
}

#root {
    width: 100%;
    height: 100%;
}

.black { color: #000; }

.textRight { text-align: right; }
.textLeft { text-align: left; }
.textCenter { text-align: center; }

.inline { display: inline; }
.block { display: block; }
.inlineBlock { display: inline-block; }

.height100 { height: 100%; }

.width100 { width: 100%; }
.width80 { width: 80%; }
.width60 { width: 60%; }

.pointer { cursor: pointer; }
.hidden { display: none; }

.valignTop { vertical-align: top; }

.paddingBottom24 {
    padding-bottom: 24px;
}

.relative { position: relative; }

.smallLabel {
    font-size: 12px;
}
.bold { font-weight: bold; }

.flexCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.downloadArea {
    width: 100%;
    border: none;
    height: 256px;
    position: relative;
    overflow: hidden;
}


.ant-message-notice {
    text-align: right;
}
.ant-message-notice-content {
    text-align: left;
}
.ant-message-notice div[class*="ant-message-"],
.ant-message-notice div[class^="ant-message-"] {
    min-width: 500px;

}

.circleIcon {
    border-radius: 100%;
    width: 20px;
    height: 20px;
    background-color: #333;
    color: #fff;
    line-height: 24px;
    font-size: 10px;
}

.circleIcon.success {
    background-color: #52c41a;
}

.circleIcon.error {
    background-color: #e1001a;
}

.errorTxt {
    color: #e1001a;
}

.inputArea {
    max-width: 786px;
    width: 100%;
    margin: 0 auto;
}

.layoutWrapper {
    min-height: 100vh;
    width: calc(100vw - 256px);
    position: relative;
}

.dePseudonymed {
    font-style: italic;
    color: #717171;
    font-size: 0.7rem;
    line-height: 0.9rem;
}

.saveButton .menuItem:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;

}

.saveButton .menuItem.qseButton:after {
    background-color: #2f54eb;
}

.saveButton .menuItem.depseudoButton:after {
    background-color: #faad14;
}
.saveButton .menuItem.commentButton:after {
    background-color: #52c41a;
}

.resultOverlayTooltip {
    transform-origin: 900px 50% !important;
    max-width: 900px;
    width: 900px;

}

.resultOverlayTooltip .ant-tooltip-inner {
    padding: 20px;
}

.warningText {
    font-style: italic;
    font-size: 12px;
    line-height: 14px;
    color: #faad14;
}

.ratingColor > span {
    margin-right: 6px;
    width: 12px;
    display: inline-block;
    height: 12px;
    border-radius: 100%;
}
.ratingColor.darkGreen > span { background-color: #236903; }
.ratingColor.green > span { background-color: #52c41a; }
.ratingColor.orange > span { background-color: #faad14; }
.ratingColor.red > span { background-color: #e1001a; }
.ratingColor.violet > span { background-color: #a624ce; }

.treeSelectMailTemplateEntry .description {
    display: inline-block;
    text-align: right;
    font-style: italic;
    font-size: 80%;
}

.noBreak {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mr-3 {
    margin-right: 12px;
}
